import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainPage from './Pages/MainPage/MainPage'
import Header from './Modules/Header/Header'
import Footer from './Modules/Footer/Footer'
import MissionPage from './Pages/MissionPage/MissionPage'
import InprogressPage from './Pages/InprogressPage/InprogressPage'
import Contact from './Pages/Contacts/Contacts'
import NewsPage from './Pages/NewsPage/NewsPage'
import EquipmentPage from './Pages/EquipmentPage/EquipmentPage'
import ThermoplasticPage from './Pages/ThermoplasticPage'
import CarbonFiberPage from './Pages/CarbonFiberPage'
import MetalworkingPage from './Pages/MetalworkingPage'
import StonePage from './Pages/StonePage'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/mission" element={<MissionPage />} />
          <Route path="/inprogress" element={<InprogressPage />} />
          <Route path="/contacts" element={<Contact />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/equipment" element={<EquipmentPage />} />
          <Route path="/thermoplastic" element={<ThermoplasticPage />} />
          <Route path="/carbon-fiber" element={<CarbonFiberPage />} />
          <Route path="/metalworking" element={<MetalworkingPage />} />
          <Route path="/stone" element={<StonePage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
