import React from 'react'
import MetalworkingMain from './MetalworkingMain/MetalworkingMain'
import MetalworkingMachiningCenters from './MetalworkingMachiningCenters/MetalworkingMachiningCenters'
import MetalworkingWaterjet from './MetalworkingWaterjet/MetalworkingWaterjet'
import ContactsBlock from '../ContactsBlock/ContactsBlock'

const Index = () => {
  return (
    <div>
      <MetalworkingMain />
      <MetalworkingMachiningCenters />
      <MetalworkingWaterjet />
      <ContactsBlock />
    </div>
  )
}

export default Index
