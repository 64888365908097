import React from 'react'
import style from './HybridSystemssCarbon.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const HybridSystemsCarbon = () => {
  const { t } = useTranslation()
  return (
    <div className={style.engineering} id="engineering">
      <div className={`${'customContainer'} ${style.engineering_wrap}`}>
        <h2 className={style.title}>{t('hybridSystemsCarbon.title')}</h2>
        <Link to={'http://prostir3d.com'} className={style.button}>
          {t('hybridSystemsCarbon.button')}
        </Link>
      </div>
    </div>
  )
}

export default HybridSystemsCarbon
